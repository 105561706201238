var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.title,
        visible: _vm.dialogVisible,
        "close-on-press-escape": false,
        width: "60%",
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.onHandleClose,
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dialogLoading,
              expression: "dialogLoading",
            },
          ],
          staticClass: "contents",
        },
        [
          _c(
            "c-screenshots",
            {
              ref: "cScreenshots",
              staticClass: "qr-code-box",
              attrs: { width: "200px", ratio: 2 },
            },
            [
              _c("div", { staticClass: "content-wrap" }, [
                _c("img", {
                  staticClass: "qr-code",
                  attrs: { src: _vm.qrCodeBase64, alt: "" },
                }),
                _c("div", { staticClass: "name" }, [
                  _vm._v(_vm._s(_vm.fileName)),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.onHandleClose } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", disabledLoading: _vm.disabledLoading },
              on: { click: _vm.onHandleSubmit },
            },
            [_vm._v("保存到本地")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }