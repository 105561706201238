<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    :close-on-press-escape="false"
    @close="onHandleClose"
    width="60%"
    class="dialog"
    center
  >
    <div class="contents" v-loading="dialogLoading">
      <!-- 截屏区域 start -->
      <c-screenshots
        ref="cScreenshots"
        width="200px"
        :ratio="2"
        class="qr-code-box"
      >
        <div class="content-wrap">
          <img :src="qrCodeBase64" class="qr-code" alt="" />
          <div class="name">{{ fileName }}</div>
        </div>
      </c-screenshots>
      <!-- 截屏区域 end -->
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onHandleClose">取 消</el-button>
      <el-button
        type="primary"
        @click="onHandleSubmit"
        :disabledLoading="disabledLoading"
        >保存到本地</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { generateQRCode, downloadFile } from "@/utils/tools/qrCode.js";
import CScreenshots from "@/components/common/CScreenshots";

export default {
  name: "QrCodeDialog",
  props: {
    title: {
      type: String,
      default: "二维码",
    },
  },
  components: {
    CScreenshots,
  },
  data() {
    return {
      dialogVisible: false,
      dialogLoading: false,
      disabledLoading: false,
      qrCodeUrl: "", // 二维码链接
      qrCodeBase64: "", // 二维码base64
      fileName: "", // 文件名称
    };
  },
  methods: {
    /**
     * 初始化
     */
    onInitData(data) {
      const { url, name } = data;
      this.qrCodeUrl = url;
      this.fileName = name;
      this.dialogVisible = true;
      this.dialogLoading = true;
      url && this.onHandleQrCode();
    },
    /**
     * 关闭操作
     */
    onHandleClose() {
      this.dialogVisible = false;
      this.dialogLoading = false;
      this.qrCodeUrl = "";
      this.qrCodeBase64 = "";
    },
    /**
     * 保存图片到本地
     */
    onHandleSubmit() {
      this.getFileBase64();
    },
    /**
     * 生成小程序二维码
     */
    async onHandleQrCode() {
      const params = {
        url: this.qrCodeUrl,
      };
      try {
        const res = await generateQRCode(params);
        this.qrCodeBase64 = res;
      } catch (err) {
        console.log("onHandleQrCode err", err);
      } finally {
        this.dialogLoading = false;
      }
      // console.log("🆒 onHandleQrCode", res);
    },
    /**
     * 获取文件信息
     */
    async getFileBase64() {
      try {
        this.disabledLoading = true;
        const base64 = await this.$refs.cScreenshots.screenshots(true, "png");
        if (base64) {
          // let img = document.createElement('img');
          // img.src = base64;
          downloadFile(base64, this.fileName);
          this.onHandleClose();
        }
      } catch (err) {
        console.log("getFileBase64 err", err);
      } finally {
        this.disabledLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .contents {
    padding-top: 40px;
    padding-bottom: 30px;
    z-index: 0;
    .qr-code-box {
      position: relative;
      z-index: 1;
      .content-wrap {
        width: 200px;
        height: 100%;
        padding: 15px;
        margin: 0 auto;
        background-color: #fff;
        .qr-code {
          width: 200px;
          height: 200px;
          z-index: 2;
        }
        .name {
          padding-top: 15px;
          text-align: center;
        }
      }
    }
  }
}
</style>
