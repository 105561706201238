<template>
  <!-- 截图组件 start -->
  <div ref="content">
    <slot />
  </div>
  <!-- 截图组件 end -->
</template>

<script>
import domToImages from "dom-to-image";
import html2canvas from "html2canvas";

export default {
  name: "CScreenshots",
  props: {
    /**
     * 宽度
     */
    width: {
      type: String,
      default: "auto",
    },
    /**
     * 图片倍率
     */
    ratio: {
      type: Number,
      default: 1,
    },
    /**
     * 生成卡片的类型 canvas / html2canvas
     */
    model: {
      type: String,
      default: "html2canvas",
    },
  },
  methods: {
    /**
     * @description: 动态获取内容高度
     */
    height() {
      let height = this.$refs.content?.offsetHeight;
      let res = height + "px";
      return res;
    },
    /**
     * 截图方法
     * @param {Boolean} distinct 清晰模式
     * @param {String} fileType 文件类型 png/jpeg
     */
    screenshots(distinct, fileType = "png") {
      return this.$nextTick().then(() => {
        // 拷贝元素
        const content = document.createElement("div");
        content.innerHTML = this.$refs.content.innerHTML;
        const wrap = document.createElement("div");
        wrap.className = "screenshots-fiexd";
        wrap.appendChild(content);
        let width = this.width;
        let height = this.height();
        // console.log('🐸 methods screenshots', height);
        this.setStyle(content, {
          width,
          height,
        });
        this.$refs.content.appendChild(wrap);
        // 绘制清晰图片
        if (distinct) {
          return this.drawDistinctCanvas(content, wrap, fileType);
        }
        let convertImg;
        switch (fileType) {
          case "jpeg":
            convertImg = domToImages.toJpeg(content);
            break;
          default:
            convertImg = domToImages.toPng(content);
            break;
        }
        return convertImg
          .then(function (dataUrl) {
            return dataUrl;
          })
          .finally(() => {
            this.$refs.content.removeChild(wrap);
          });
      });
    },
    /**
     * 设置样式
     * @param {HTMLElement} el dom元素
     * @param {Object} styles 样式
     */
    setStyle(el, styles) {
      Object.keys(styles).forEach((key) => {
        el.style[key] = styles[key];
      });
    },
    /**
     * 通过绘制倍率canvans，导出清晰图片
     * @param {HTMLElement} content 渲染的dom
     * @param {HTMLElement} wrap 临时容器
     * @param {String} fileType 文件类型 jpeg png
     */
    drawDistinctCanvas(content, wrap, fileType) {
      // console.log(content);
      var rect = content.getBoundingClientRect();
      let width = rect.width;
      let height = rect.height;
      if (this.width) {
        width = this.width.replace("px", "") * this.ratio;
      }
      if (this.height()) {
        height = this.height().replace("px", "") * this.ratio;
      }
      let canvas = document.createElement("canvas");
      let ratio = window.devicePixelRatio; // 设定缩放比例
      // 默认生成二倍图
      if (ratio < 2) ratio = 2;
      if (this.ratio) ratio = this.ratio;
      canvas.width = width;
      canvas.height = height;
      canvas.style.width = width + "px";
      canvas.style.height = height + "px";
      let ctx = canvas.getContext("2d");
      // 将dom中的图片转为 blob
      // if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      // }
      // 转svg保持清晰
      return domToImages
        .toSvg(content, {
          quality: 1.25,
        })
        .then((dataUrl) => {
          // console.log('🥔 drawDistinctCanvas', dataUrl);
          return this.getCanvasDataUrl({
            canvas,
            ctx,
            width,
            height,
            dataUrl,
            fileType,
            ratio,
          });
        })
        .finally(() => {
          this.$refs.content.removeChild(wrap);
        });
    },
    /**
     * 获取canvans输出dataurl，将大小乘以设备分辨率，保证图片清晰度
     */
    getCanvasDataUrl({ canvas, ctx, width, height, ratio, dataUrl, fileType }) {
      return new Promise((resolve) => {
        let img = new Image();
        // console.log('🥔 getCanvasDataUrl', dataUrl);
        img.src = dataUrl;
        let exportType;
        switch (fileType) {
          case "jpeg":
            exportType = "image/jpeg";
            break;
          default:
            exportType = "image/png";
            break;
        }
        let _el = this.$refs.content;
        img.onload = function () {
          if (this.model === "html2canvas") {
            // 采用 html2canvas 方式
            html2canvas(_el, {
              scale: ratio,
              allowTaint: true,
              useCORS: true,
              canvas,
            })
              .then((_canvas) => {
                try {
                  const base64 = _canvas.toDataURL(exportType);
                  // console.log('🐸 getCanvasDataUrl', base64);
                  resolve(base64);
                } catch (err) {
                  console.log("html2canvas err", err);
                }
              })
              .catch((err) => {
                console.log("html2canvas err", err);
              });
          } else {
            // 采用 canvas 方式
            ctx.drawImage(
              img,
              0,
              0,
              width,
              height,
              0,
              0,
              ratio * width,
              ratio * height
            );
            let base64 = canvas.toDataURL(exportType);
            // console.log('🐸 getCanvasDataUrl', base64);
            resolve(base64);
          }
        };
      });
    },
  },
};
</script>

<style lang="less">
.screenshots-fiexd {
  top: 0;
  position: fixed;
  z-index: -1;
  height: 0;
  overflow: hidden;
}
</style>
