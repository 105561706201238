import jrQrcode from 'jr-qrcode';

/**
 * @description: 生成二维码
 * @param {String} params.url 链接或文字路径 -必传
 * @param {Number} params.width 二维码宽度 -非必传
 * @param {Number} params.height 二维码高度 -非必传
 * @param {Function} params.callback 是否需回调操作 -非必传
 */
export const generateQRCode = async (params) => {
  const { url, width = 300, height = 300, callback = null} = params;
  try {
    const qrCode = jrQrcode.getQrBase64(url, {
      correctLevel: window.QRErrorCorrectLevel.M,
      width,
      height,
      foreground: '#000',
      padding: 0,
    });
    callback && callback(qrCode);
    return qrCode;
  } catch (err) {
    console.error(err);
  }
}

/**
 * @description: 下载 base64 格式的图片
 * @param {base64} content 文件内容 -必传
 * @param {Number} name 文件名称 -非必传
 */
export function downloadFile(content, name = '久农科技-图片') {
  const aLink = document.createElement('a');
  const blob = base64ToBlob(content);
  const evt = document.createEvent('HTMLEvents');
  /** 两个参数不加会在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为 */
  evt.initEvent('click', true, true);
  aLink.download = name;
  aLink.href = URL.createObjectURL(blob);
  aLink.click();
}

/**
 * @description: 将 base64 格式转 blob 格式
 * @param {base64} content 文件内容 -必传
 */
export function base64ToBlob(content) {
  const parts = content.split(';base64,');
  const contentType = parts[0].split(':')[1];
  console.log("🆒 base64ToBlob", parts);
  // 解码base64得到二进制字符串
  const raw = window.atob(parts[1]);
  const rawLength = raw.length;
  // 创建8位无符号整数值的类型化数组
  let uInt8Array = new Uint8Array(rawLength);
  for (let i = 0; i < rawLength; ++i) {
    // 数组接收二进制字符串
    uInt8Array[i] = raw.charCodeAt(i);
  }
  return new Blob([uInt8Array], { type: contentType });
}
